@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .animation-delay-2000 {
      animation-delay: 2000ms;
  }
}

body {
  @apply font-base text-body bg-gray-50 leading-loose;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.button-teal {
  @apply py-2 px-4 bg-dark text-yellow-100 text-lg rounded hover:bg-teal-600;
}